import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import readingTime from 'reading-time'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Tags from '../components/tags'
import * as styles from './blog-post.module.css'

const PageTemplate = ({ data, location }) => {
  const page = data.contentfulPage
  const plainTextDescription = documentToPlainTextString(
    JSON.parse(page.description.raw)
  )

  const plainTextBody = documentToPlainTextString(JSON.parse(page.body.raw))

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage, description } = node.data.target
        return <GatsbyImage image={getImage(gatsbyImage)} alt={description} />
      },
      'embedded-asset-block': (node) => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          // asset is not an image
          return <></>
        }
        return <GatsbyImage image={gatsbyImageData} />
      },
    },
  }

  return (
    <Layout location={location}>
      <Seo
        title={page.title}
        description={plainTextDescription}
        image={`http:${page.heroImage.resize.src}`}
      />
      <Hero
        image={page.heroImage?.gatsbyImage}
        title={page.title}
        content={page.description}
      />
      <div className={styles.container}>
        <div className={styles.article}>
          <div className={styles.body}>
            {page.body?.raw && renderRichText(page.body, options)}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      slug
      title
      author {
        name
      }
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      description {
        raw
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
    }
  }
`
